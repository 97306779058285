<template>
  <div class="content">
    <div class="top">
      <span class="size36">董事长寄语</span>
      <p>Message</p>
    </div>
    <div class="next">
      <!-- <img src="../../../static/images/home/people.jpg" alt="" /> -->
      <div class="img"></div>
      <div class="right">
        <div class="name">
          <span class="size28">刘勇</span>
          <p>和行科技 董事长</p>
        </div>
        <div class="message">
          <p>
            百余年历史的汽车产业，正面临颠覆性变革。在中国，汽车电动化、智能化、网联化、共享化正以一种相互促进、递进、融合的关系，不断拓展。汽车四化为代表的未来新方式也将推动服务变革，移动出行给我们带来更多的想象空间，各大车企都在搭建自己的移动出行平台。
          </p>
          <br />
          <p>
            “和行约车”是江汽集团顺应行业发展趋势，积极探索推进的“互联网+出行”新业务。经过数年的积淀磨炼，我们依托江汽集团丰富的产品品类，将全球领先的互联网、车联网以及汽车科技，应用于共享出行领域，致力于为用户提供安全、快捷、舒适、绿色的一站式出行解决方案。
          </p>
          <br />
          <p>
            展望未来，和行约车将持续丰富和完善产品布局，着力探索搭建特色鲜明的行业赛道，提升研发实力，把握新时代特征，力争为更多用户带来更多元、更高品质的出行服务体验。我们将始终静守初心，砥砺奋斗，赓续前行。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  font-weight: 400;
  color: #2d2d39;

  .top {
    box-sizing: border-box;
    padding: 3% 0 0 4%;

    .size36 {
      font-size: 36px;
    }

    p {
      margin-left: 1px;
      font-size: 16px;
    }
  }

  .next {
    width: 10rem;
    height: 4.5rem;
    margin: .6rem auto;
    display: flex;
    background: rgba(245, 245, 245, 1);

    .img {
      width: 3.5rem;
      height: 4.5rem;
      background-image: url('../../../static/images/home/people.jpg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .right {
      flex: 1;
      font-size: .14rem;
      padding: .3rem .5rem .1rem;

      .name {
        width: 100%;

        .size28 {
          font-size: 28px;
        }

        p {
          margin-top: .15rem;
          color: #949499;
        }
      }

      .message {
        width: 100%;
        margin-top: .35rem;

        p {
          display: block;
          margin-top: -9px;
          line-height: 25px;
        }
      }
    }
  }
}
</style>